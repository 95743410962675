const devideby = 10000000000;
const acf_library = require("./acf_library");
/*
const htmlDecode = function(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};
*/

function htmlDecode(encodedString) {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">"
  };
  return encodedString
    .replace(translate_re, function(match, entity) {
      return translate[entity];
    })
    .replace(/&#(\d+);/gi, function(match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
}

const roundLongLat = a => {
  //return a;
  return Math.round(a * devideby) / devideby;
};

const genGeoIndex = (a, b) => {
  a = Math.round(a * devideby) / devideby;
  b = Math.round(b * devideby) / devideby;
  return "geo" + a + b;
};

const calcClustering = features => {
  //features.forEach
  let multiMarkerList = {};
  let stackedMarkers = {};

  for (let index = 0; index < features.length; index++) {
    //let properties = features[index].properties;

    features[index].properties.stackIndex = false;
    features[index].properties.hasStacking = false;
    features[index].properties.hideForStacking = false;

    try {
      let geoIndex = features[index].properties.geoIndex;
      if (!multiMarkerList[geoIndex]) {
        multiMarkerList[geoIndex] = [];
      } else {
        //item already on this point
        features[index].properties.hideForStacking = true;
      }

      multiMarkerList[geoIndex].push(index);
      if (multiMarkerList[geoIndex].length > 1) {
        stackedMarkers[geoIndex] = multiMarkerList[geoIndex];
      }
    } catch (error) {
      //do nothing
    }
  }

  for (let index = 0; index < features.length; index++) {
    let geoIndex = features[index].properties.geoIndex;

    if (stackedMarkers[geoIndex]) {
      //for manual stack, if title is longer than x, add '...'

      features[index].geometry.coordinates[0] = roundLongLat(
        features[index].properties.Latitude
      );
      features[index].geometry.coordinates[1] = roundLongLat(
        features[index].properties.Longitude
      );

      let stackedIndexLabel = stackedMarkers[geoIndex]
        .map(n => {
          //return n + 1;

          return features[n].properties.indexLabel;
        })
        .join(", ");

      stackedIndexLabel =
        stackedIndexLabel.length > 9
          ? stackedIndexLabel.substring(0, 9) + "..."
          : stackedIndexLabel;

      features[index].properties.stackedIndexLabel = stackedIndexLabel;

      features[index].properties.hasStacking = true;
      features[index].properties.stackIndex = multiMarkerList[geoIndex].indexOf(
        index
      );

      let stackIndex = features[index].properties.stackIndex;

      const offsetAmount = 1;
      const enableMarkerOffsetPatter = false;

      features[index].properties.stackOffset = [
        -1 * offsetAmount * features[index].properties.stackIndex,
        -1 * offsetAmount * features[index].properties.stackIndex
      ];

      //stacking offset stuff;
      if (enableMarkerOffsetPatter) {
        features[index].properties.stackOffset =
          stackIndex === 0
            ? [0, 10]
            : stackIndex === 1
            ? [-5, -5]
            : stackIndex === 2
            ? [5, -5]
            : [0, 10];
      }
    }
  }

  return { features, stackedMarkers };
};

const hackTaxonomy = function(taxonomy) {
  if (taxonomy.marker_institute && taxonomy.marker_institute.length) {
    taxonomy.marker_institute.forEach(row => {
      for (const [key, value] of Object.entries(row)) {
        //console.log(`${key}: ${value}`);
        //this is to fix WYSIWYG editor colums from ACF
        if (key.endsWith("_clean")) {
          if (row.acf[key.replace("_clean", "")]) {
            row.acf[key.replace("_clean", "")].value = value.value;
          }
        }
      }
      /*
        for (let key of row) {

          //if (key.endsWith("_clean")) {
       
       
            if (row.acf[key.replace("_clean", "")]) {
            //console.log("found!!!");
            row.acf[key.replace("_clean", "")].value = row[key].get_field;
          }
      }
*/
    });
  }

  return taxonomy;
};

const genGeoJsonAndConfig = async ({ post, taxonomy }, targets) => {
  taxonomy = hackTaxonomy(taxonomy);
  let linkedDataSets = {};

  try {
    let maptivateSetupObject = {};
    let output = {};
    let geoJsonFeatures = [];
    let sectionArray = [];
    let markerImages = {};
    let setTaxonomySetOrder = [];
    let setListTaxonomySetOrder = [];

    let listCategoryLookup = {};

    //let sectionTypes = ["main_marker", "artwork", "signage", "other"];
    let geoJsonByType = {};
    targets.forEach(
      ({
        postId,
        listTaxonomies,
        sectionTaxonomies,
        geoData,
        isSetupObject,
        isLinkedData
      }) => {
        if (isLinkedData === true) {
          if (post[postId]) {
            linkedDataSets[postId] = post[postId];
          }
        }

        if (taxonomy[listTaxonomies]) {
          //do nothing;

          taxonomy[listTaxonomies].forEach(row => {
            row.list_section_slug = row.slug;
            row.section_type = "main_marker";
            row.list_section_type = "main_marker";
            row.title = row.name;

            row.title = row.name;
            row.padding = "15px";

            listCategoryLookup[row.slug] = row;

            setListTaxonomySetOrder.push(row.slug);
          });
        }

        if (isSetupObject === true && post[postId] && post[postId][0]) {
          maptivateSetupObject = post[postId][0];
        }

        if (geoData === true && post[postId]) {
          let setPost = post[postId];
          let setTaxonomy = taxonomy[sectionTaxonomies];

          let listTaxonomy =
            listTaxonomies && taxonomy[listTaxonomies]
              ? taxonomy[listTaxonomies]
              : null;

          setTaxonomySetOrder = [
            ...setTaxonomySetOrder,
            ...setTaxonomy.map(row => row.slug)
          ];

          let setTaxonomyObj = setTaxonomy.reduce(function(map, obj) {
            map[obj.id] = obj;
            //map[obj.slug] = obj;
            return map;
          }, {});
          sectionArray = [...sectionArray, ...setTaxonomy];
          setPost.forEach(post => {
            /*
            let { markersection, artwork_section } = post;

            if (artwork_section && !markersection) {
              markersection = artwork_section;
            }*/

            let markersection = post[sectionTaxonomies];

            let listTaxonomiesValue = post[listTaxonomies];

            let markerListSectionObj = false;
            //and menu list taxonomy
            if (listTaxonomy && listTaxonomiesValue && listTaxonomiesValue[0]) {
              markerListSectionObj = listTaxonomy.filter(row => {
                return row.id === listTaxonomiesValue[0];
              })[0];

              //;

              //Menu;
            }

            let markerSectionObj = false;
            if (
              setTaxonomyObj &&
              markersection[0] &&
              setTaxonomyObj[markersection[0]]
            ) {
              markerSectionObj = setTaxonomyObj[markersection[0]];
            } else {
              //markerSectionObj = setTaxonomy[0];
              console.error("error, post has no section", {
                post,
                markersection,
                setTaxonomyObj
              });
            }

            if (
              markerSectionObj &&
              post &&
              post.cust_location &&
              post.cust_location[1] &&
              post.status == "publish" &&
              (markerSectionObj?.featured_image_obj?.id ||
                post?.featured_image_obj?.id)
            ) {
              let section_type = markerSectionObj?.acf?.type?.value;

              let section_id = markerSectionObj?.id;
              let section_colour = markerSectionObj?.acf?.colour?.value;
              let section_name = markerSectionObj?.name;

              section_colour = section_colour ? section_colour : "black";

              let section_slug = markerSectionObj?.slug;

              let section_prfixCode = markerSectionObj?.acf?.PrifixCode?.value
                ? markerSectionObj?.acf?.PrifixCode?.value
                : section_id;

              let icon_obj = null; //{id, url, title}

              let { id, href, title, thumb, marker, marker_lg } =
                post?.featured_image_obj?.href && post?.featured_image_obj?.id
                  ? post.featured_image_obj
                  : markerSectionObj.featured_image_obj;

              let scale_factor = false;
              let isArtwork = false;
              if (section_type === "artwork") {
                isArtwork = true;
              }

              if (!isNaN(post?.acf?.MO_artwork_scale_factor?.value)) {
                try {
                  scale_factor = parseFloat(
                    post?.acf?.MO_artwork_scale_factor?.value
                  );
                  if (scale_factor === 1 || scale_factor === 0) {
                    scale_factor = false;
                  }
                } catch (error) {
                  //do nothing;
                }
              }

              if (id && href) {
                icon_obj = { id, href, title, thumb, marker };
                if (isArtwork === true) {
                  icon_obj.isArtwork = true;
                  icon_obj.marker_lg = marker_lg;
                  icon_obj.section_slug = section_slug;
                }
                if (scale_factor !== false) {
                  icon_obj.scale_factor = scale_factor;
                }
                markerImages[id] = icon_obj;
              }

              if (post.media_urls.length && post.featured_media) {
                //post.featured_media //id
                post.media_urls = post.media_urls.filter(row => {
                  return row.ID != post.featured_media;
                });
              }

              let customData = {};

              //setTaxonomyObj
              const mergeInTaxCustData = true;
              if (mergeInTaxCustData) {
                let taxonomyObjAcf = markerListSectionObj.acf;
                for (let key in taxonomyObjAcf) {
                  if (
                    taxonomyObjAcf[key] &&
                    key !== "location" &&
                    key.startsWith("M_")
                  ) {
                    customData[key] = taxonomyObjAcf[key];
                  }
                }
              }

              for (let key in post.acf) {
                if (post.acf[key] && key !== "location") {
                  customData[key] = post.acf[key];
                }
              }

              let structuredData = acf_library.processAcfData(customData);

              //post.cust_location[1] = roundLongLat(post.cust_location[1]);
              //post.cust_location[0] = roundLongLat(post.cust_location[0]);

              let geoIndex = genGeoIndex(
                post.cust_location[1],
                post.cust_location[0]
              );

              let Site_Marker_Number_numPart = customData?.M_ST_Site_ID?.value
                ? customData.M_ST_Site_ID?.value
                : "post_" + post.id;

              let Site_Marker_Number_Label = Site_Marker_Number_numPart;
              let Site_Marker_Number =
                section_prfixCode + "__" + Site_Marker_Number_numPart;

              let Point_Name = htmlDecode(
                post?.title?.rendered ? post.title.rendered : post.title
              );

              if (Point_Name.includes("|||")) {
                Point_Name = Point_Name.split("|||")[1].trim();
              }

              let name = Point_Name;

              let listSectionDetails = {};

              if (
                markerListSectionObj &&
                markerListSectionObj.title &&
                markerListSectionObj.slug
              ) {
                let Institute_Name_And_Point_Name =
                  markerListSectionObj.title + " - " + Point_Name;

                let Institute_Code_And_Point_Name =
                  markerListSectionObj.slug.toUpperCase() + " - " + Point_Name;

                listSectionDetails = {
                  Institute_Name_And_Point_Name,
                  Institute_Code_And_Point_Name
                };

                name = Institute_Name_And_Point_Name; //name is used by screen reader;
              }

              let markerObj = {
                // feature for Mapbox DC
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [post.cust_location[1], post.cust_location[0]]
                },

                properties: {
                  name,
                  stackIndex: Site_Marker_Number,
                  hasStacking: false,
                  hideForStacking: false,
                  geoIndex: geoIndex,
                  hideMarkerButMakeClickable: "false",

                  Point_Name,
                  ...listSectionDetails,
                  Site_Marker_Number,
                  Site_Marker_Number_Label,

                  Longitude: post.cust_location[0],
                  Latitude: post.cust_location[1],

                  post_id: post.id,

                  slug: post.slug,
                  content: post.content,
                  media:
                    post.media_urls && post.media_urls.length
                      ? post.media_urls
                      : null,
                  //customData,
                  structuredData,
                  date: post.date,
                  modified: post.modified,
                  section_type,
                  section_id,
                  section_slug,
                  section_colour,
                  section_name,
                  list_section_slug: markerListSectionObj.slug
                    ? markerListSectionObj.slug
                    : null,
                  list_section_id: markerListSectionObj.id
                    ? markerListSectionObj.id
                    : null,

                  list_section_title: markerListSectionObj.title
                    ? markerListSectionObj.title
                    : null,

                  post_type_slug: postId,

                  icon_obj,
                  icon_id: icon_obj?.id ? icon_obj?.id : false
                }
              };

              if (!geoJsonByType["geoJson_" + section_type]) {
                geoJsonByType["geoJson_" + section_type] = {
                  type: "FeatureCollection",
                  features: []
                };
              }
              geoJsonFeatures.push(markerObj);

              geoJsonByType["geoJson_" + section_type].features.push(markerObj);
            }
          });
        }
      }
    );

    let sectionObj = {};
    let categoryLookup = {};

    let sectionOrder = 0;
    sectionArray.forEach(row => {
      let {
        id,
        count,
        description,
        name,
        slug,
        taxonomy,
        featured_image_obj,
        list_image_obj,
        acf
      } = row;

      sectionOrder++;

      let SectionLink = acf?.SectionLink?.value;

      //add anchor data;
      let sectionIconAnchor = false;
      try {
        sectionIconAnchor =
          acf["icon-anchor"] && acf["icon-anchor"]?.value
            ? acf["icon-anchor"]?.value
            : false;
      } catch (error) {
        //do nothing
      }

      let colour = acf?.colour?.value;
      colour = colour ? colour : "black";
      let type = acf?.type?.value;

      if (!list_image_obj?.href) {
        list_image_obj = featured_image_obj;
      }

      let options = acf_library.processAcfTaxonomy(acf);

      if (count) {
        categoryLookup[slug] = {
          id,
          key: slug,
          count,
          description,
          title: name,
          name,
          section_slug: slug,
          taxonomy,
          list_image_obj,
          featured_image_obj,
          SectionLink: SectionLink,
          color: colour,
          section_type: type,
          sectionIconAnchor,
          padding: "15px",
          visible: type != "artwork" && type != "other" ? true : false,
          options,
          sectionOrder
        };

        sectionObj[slug] = {
          id,
          key: slug,
          count,
          description,
          title: name,
          name,
          section_slug: slug,
          taxonomy,
          list_image_obj,
          featured_image_obj,
          SectionLink: SectionLink,
          sectionIconAnchor,
          color: colour,
          section_type: type,
          padding: "15px",
          options,
          sectionOrder
        };
      }
    });

    /*
    //sort by cat order !!!
    let rawFeatues = geoJsonByType.geoJson_main_marker.features;
    let features = [];
    setTaxonomySetOrder.forEach(orderKey => {
      if (categoryLookup[orderKey]) {
        let key = categoryLookup[orderKey].key;
        let cat = categoryLookup[key];
        features = [
          ...features,
          ...rawFeatues.filter(
            point => point.properties.section_slug == cat.section_slug
          )
        ];
      }
    });
    */
    //setListTaxonomySetOrder

    //sort by cat order !!!
    let rawFeatues = geoJsonByType.geoJson_main_marker.features;
    let features = [];
    setListTaxonomySetOrder.forEach(orderKey => {
      if (listCategoryLookup[orderKey]) {
        //let key = listCategoryLookup[orderKey].key;
        let cat = listCategoryLookup[orderKey];
        features = [
          ...features,
          ...rawFeatues.filter(
            point => point.properties.list_section_slug == cat.list_section_slug
          )
        ];
      }
    });

    let temp = calcClustering(features);

    let stackedMarkers = temp.stackedMarkers;
    features = temp.features;

    geoJsonByType.geoJson_main_marker.features = features;

    let id = 0;
    geoJsonByType.geoJson_main_marker.features.forEach(row => {
      row.id = id;
      id++;
    });

    if (geoJsonByType?.geoJson_signage?.features) {
      geoJsonByType.geoJson_signage.features.forEach(row => {
        row.id = id;
        id++;
      });
    }
    if (geoJsonByType?.geoJson_artwork?.features) {
      geoJsonByType.geoJson_artwork.features.forEach(row => {
        row.id = id;
        id++;
      });
    }

    let { eventsFormated, eventsByTime, eventsLookup } = formatEvents(
      linkedDataSets
    );

    output = {
      maptivateSetupObject,
      stackedMarkers,
      sections: sectionObj,
      markerImages,
      ...geoJsonByType,
      categoryLookup,
      categoryOrder: setTaxonomySetOrder,
      listCategoryLookup,
      linkedDataSets,

      eventsFormated,
      eventsByTime,
      eventsLookup
    };

    return output;
  } catch (error) {
    console.error("errorC", error);
  }
};

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    //console.log(a[property]);
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
const moment = require("moment");

const formatEvents = linkedDataSets => {
  let eventsFormated = {};
  let eventsByTime = {};
  let eventsLookup = {};

  if (linkedDataSets.events) {
    let events = linkedDataSets.events;

    events.forEach(row => {
      let { acf, id: postId } = row;

      let title = row?.title?.rendered;
      let linkedMarkerId = acf?.M_FLYOBJ_POINT?.value;
      let startDateTime = acf?.M_EVENDATETIME_StartDatetime?.value;
      let endTime = acf?.M_EVENTIME_EndTime?.value;
      let description = acf?.M_NO_Description?.value;

      let startDateTimeFormated = startDateTime;
      let endTimeFormated = endTime;

      if (startDateTimeFormated) {
        try {
          let temp = moment(startDateTimeFormated, "YYYY-MM-DD H:m:s");
          startDateTimeFormated = temp.format("dddd h:mm a");
        } catch (e) {
          //do nothing
        }
      }
      if (endTimeFormated) {
        try {
          let temp = moment(endTimeFormated, "H:m:s");
          endTimeFormated = temp.format("h:mm a");
        } catch (e) {
          //do nothing
        }
      }

      description = description.replaceAll("\n", "").replaceAll("\r", "<br/>");

      if (linkedMarkerId && startDateTime) {
        if (!eventsFormated[linkedMarkerId]) {
          eventsFormated[linkedMarkerId] = [];
        }

        let timeIndex = startDateTime;

        if (!eventsByTime[timeIndex]) {
          eventsByTime[timeIndex] = [];
        }

        let newRow = {
          title,
          postId,
          linkedMarkerId,
          startDateTime,
          endTime,
          description,
          startDateTimeFormated,
          endTimeFormated
        };

        eventsFormated[linkedMarkerId].push(newRow);

        eventsByTime[timeIndex].push(newRow);
        eventsLookup[postId] = newRow;

        if (eventsFormated[linkedMarkerId].length > 1) {
          eventsFormated[linkedMarkerId].sort(dynamicSort("startDateTime"));
        }

        if (eventsByTime[timeIndex].length > 1) {
          eventsByTime[timeIndex].sort(dynamicSort("title"));
        }
      }
    });
  }

  return { eventsFormated, eventsByTime, eventsLookup };
};

module.exports.genGeoJsonAndConfig = genGeoJsonAndConfig;
