<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> <v-icon> mdi-calendar-text-outline</v-icon> 
          <font v-show="$vuetify.breakpoint.smAndUp">Open Schedule</font>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="rgb(162, 194, 205)">
             <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-icon> mdi-calendar-text-outline</v-icon> Conference
            Schedule</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items> </v-toolbar
        ><ScheduleList />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { eventBus } from "../../main";

import ScheduleList from "./ScheduleList.vue";
export default {
  name: "ConferenceScheduleDialog",
  components: {
    ScheduleList
  },
  data() {
    return {
      dialog: false
    };
  },
  mounted() {
    eventBus.$on("nav_schedule_setOpenState", value => {
      this.dialog = value ? true : false;
    });
  }
};
</script>
