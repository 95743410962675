<template>
  <div>
    <div v-for="(item, index) in orderedItems" :key="`${viewType}_${index}`">
      <template v-if="item.sectionKey === 'subtitle'">
        <v-card-subtitle
          class="py-0 secondary--text font-weight-bold text--darken-1"
          v-if="item.value"
        >
          <template v-if="item.label"> {{ item.label }} : </template>
          {{ item.value }}
        </v-card-subtitle>
      </template>

      <template
        v-if="item.sectionKey === 'note' || item.sectionKey === 'value'"
      >
        <v-card-subtitle
          class="py-0"
          v-if="item.value && item.subSection !== 'textarea'"
        >
          <b v-if="item.label"> {{ item.label }} : </b>

          {{ item.value }}
        </v-card-subtitle>

        <v-card-subtitle
          :class="[item.key, 'py-0']"
          v-if="item.value && item.subSection === 'textarea'"
        >
          <b v-if="item.label" style="    display: block;">
            {{ item.label }} :
          </b>

          <font class="textareaAct" v-html="item.value" />
        </v-card-subtitle>
      </template>

      <template v-if="item.sectionKey === 'datetime'">
        <v-card-subtitle class="py-0" v-if="item.value">
          <b v-if="item.label"> {{ item.label }} : </b>
          <nobr>{{ item.value }}</nobr>
        </v-card-subtitle>
      </template>

      <template
        v-if="item.sectionKey === 'link' && item.subSection != 'special'"
      >
        <v-card-subtitle class="py-0" v-if="item.url">
          <b v-if="item.label"> {{ item.label }} : </b>

          <a
            v-show="item.url"
            :href="item.url"
            target="_blank"
            style="text-decoration: underline!important;"
            class="accent--text"
          >
            {{ item.title ? item.title : item.url }}
          </a>
        </v-card-subtitle>
      </template>

      <template
        v-if="item.sectionKey === 'link' && item.subSection === 'special'"
      >
        <v-card-subtitle class="py-0" v-if="item.value">
          <b>
            <a
              v-show="item.value"
              :href="item.value"
              target="_blank"
              style="text-decoration: underline!important;"
              class="accent--text"
            >
              {{ item.label ? item.label : item.url }}
            </a>
          </b>
        </v-card-subtitle>
      </template>

      <template v-if="item.sectionKey === 'videolink'">
        <VideoPlayButton
          v-if="item.url"
          :Point_Name="Point_Name"
          :Site_Marker_Number="Site_Marker_Number"
          :sectionOrder="item.sectionOrder"
          :sectionCount="item.sectionCount"
          :videoUrl="item.url"
          :title="item.title ? item.title : null"
          :openYoutube="functions.openYoutube"
          :width="buttonWidth"
          :appSettings="appSettings"
          :isPopup="isPopup"
        />
      </template>

      <template v-if="item.sectionKey === 'gallerybutton'">
        <LoadGalleryButton
          :Point_Name="Point_Name"
          :Site_Marker_Number="Site_Marker_Number"
          :width="buttonWidth"
          :appSettings="appSettings"
          :media="media"
          :isPopup="isPopup"
        />
      </template>

      <template v-if="item.sectionKey === 'buttonlink'">
        <LinkButton
          v-if="item.value"
          :url="item.value"
          :title="item.label"
          :width="buttonWidth"
          :appSettings="appSettings"
          :isPopup="isPopup"
        />
      </template>
      <template v-if="item.sectionKey === 'soundcloud'">
        <AudioPlayButton
          :Point_Name="Point_Name"
          :Site_Marker_Number="Site_Marker_Number"
          :setSound="functions.setSound"
          :getAudioDetails="functions.getAudioDetails"
          :audioUrl="item.url"
          :title="item.title ? item.title : null"
          :sectionOrder="item.sectionOrder"
          :sectionCount="item.sectionCount"
          :width="buttonWidth"
          :appSettings="appSettings"
          :isPopup="isPopup"
        />
      </template>

      <template v-if="item.sectionKey === 'flytopoint'">
        <FlyToPointButton
          :functions="functions"
          :Point_Name="Point_Name"
          :Site_Marker_Number="Site_Marker_Number"
          :targetPointId="item.value"
          :sectionOrder="item.sectionOrder"
          :sectionCount="item.sectionCount"
          :width="buttonWidth"
          :appSettings="appSettings"
          :pointSet="functions.pointSet"
          :isPopup="isPopup"
        />
      </template>

      <template v-if="item.sectionKey === 'photosphere'">
        <PhotoSphereModal
          :appSettings="appSettings"
          :width="buttonWidth"
          :googleStreetIframe="item.url"
          :isPopup="isPopup"
        />
      </template>
    </div>

    <template v-if="!isJustSubtitles">
      <v-card-text
        v-if="structuredData.links && (!isPopup || showLinks['links'])"
      >
        <span class="font-weight-medium   headingText">
          Links:
        </span>
        <p
          v-for="(link, index) in structuredData.links"
          :key="`${viewType}_links_${index}`"
          class="pa-0 ma-1 "
        >
          <a
            v-show="link.url"
            :href="link.url"
            target="_blank"
            style="text-decoratin: underline!important;"
            class="accent--text"
          >
            {{ link.title ? link.title : link.url }}
          </a>
        </p>
      </v-card-text>

      <v-card-text
        v-if="
          structuredData.downloadAssets &&
            (!isPopup || showLinks['downloadAssets'])
        "
      >
        <span class="font-weight-medium   headingText">
          Documents:
        </span>
        <p
          v-for="(link, index) in structuredData.downloadAssets"
          :key="`${viewType}_docs_${index}`"
          class="pa-0 ma-1 "
        >
          <a
            :href="link.url"
            target="_blank"
            style="text-decoration: underline!important;"
            class="accent--text"
          >
            {{ link.title }}
          </a>
        </p>
      </v-card-text>

      <v-card-text
        v-if="
          showEvent &&
            postId &&
            !isJustSubtitles &&
            !isPopup &&
            eventsFormated &&
            postId &&
            eventsFormated[postId]
        "
      >
        <eventTimeline
          ref="timelineUi"
          :eventsFormated="eventsFormated"
          :postId="postId"
          v-if="postId"
        >
        </eventTimeline>
      </v-card-text>

      <v-card-text
        v-if="
          structuredData.socialmedia && (!isPopup || showLinks['socialmedia'])
        "
      >
        <span class="text-h5 text--darken-1 ">Social media: </span>

        <div>
          <v-btn
            v-for="(link, index) in structuredData.socialmedia"
            :key="`${viewType}_socialmedia_${index}`"
            small
            fab
            elevation="0"
            :href="link.url"
            target="_blank"
            class="mx-0 px-0"
            color="white"
          >
            <v-icon
              class="mx-0 px-0"
              :xcolor="appSettings.socialIcon[link.title].color"
              color="#394049"
            >
              {{ appSettings.socialIcon[link.title].icon }}</v-icon
            >
          </v-btn>
        </div>
        <div v-if="false">
          <v-btn
            v-for="(link, index) in structuredData.socialmedia"
            :key="`${viewType}_socialmedia_${index}`"
            light
            fab
            small
            :href="link.url"
            target="_blank"
            class="mx-1"
          >
            <v-icon :color="appSettings.socialIcon[link.title].color">{{
              appSettings.socialIcon[link.title].icon
            }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text
        v-if="
          appSettings.pointsHaveDirections &&
            location &&
            location.Latitude &&
            !isPopup
        "
      >
        <span class="text-h5 text--darken-1 ">Directions: </span>

        <div>
          <v-btn
            @click="functions.loadDirections(location, 'google')"
            depressed
            v-if="appSettings.pointsHaveDirections"
            :color="appSettings.mediaButtonColor"
            class="ma-2 white--text"
          >
            <v-icon left>mdi-google-maps</v-icon>
            Google Maps
          </v-btn>
          <v-btn
            @click="functions.loadDirections(location, 'apple')"
            depressed
            v-if="appSettings.pointsHaveDirections"
            :color="appSettings.mediaButtonColor"
            class="ma-2 white--text"
          >
            <v-icon left>mdi-apple </v-icon>
            Apple Maps
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import AudioPlayButton from "./../ui/AudioPlayButton.vue";
import VideoPlayButton from "./../ui/VideoPlayButton.vue";
import LoadGalleryButton from "./../ui/LoadGalleryButton.vue";
import LinkButton from "./../ui/LinkButton.vue";
import FlyToPointButton from "./../ui/FlyToPointButton.vue";
import PhotoSphereModal from "./../controls/PhotoSphereModal.vue";
import eventTimeline from "./../ui/eventTimeline.vue";

export default {
  name: "extraInfoBlocks",
  props: {
    appSettings: Object,
    location: Object,
    media: Array,
    functions: Object,
    structuredData: Object,
    eventsFormated: Object,
    postId: Number,
    Point_Name: String,
    Site_Marker_Number: String,
    isPopup: {
      type: Boolean,
      default: false
    },
    isJustSubtitles: {
      type: Boolean,
      default: false
    }
  },
  components: {
    eventTimeline,
    AudioPlayButton,
    LinkButton,
    VideoPlayButton,
    LoadGalleryButton,
    PhotoSphereModal,
    FlyToPointButton
  },
  data: () => ({
    showEvent: true,
    viewType: "moreInfo", //popup
    showMore: false,
    descriptionCropLength: 200
  }),
  watch: {
    postId: function() {
      this.$refs.timelineUi.contractView();
    }
  },
  computed: {
    buttonWidth() {
      if (this.isPopup === true) {
        return this.appSettings.popupButtonWidth;
      } else {
        let value = this.$vuetify.breakpoint.smAndUp
          ? this.appSettings.drawWidth
          : this.appSettings.mobDrawWidth;

        value = parseFloat(value) - 30;
        return `${value}px`;
      }
    },
    onlyShow() {
      return this.isJustSubtitles ? ["subtitle"] : [];
    },
    orderedItems() {
      let items = [];
      let orderedItems = {};
      //onlyShow

      let sideBarExclude = this.isPopup ? [] : ["subtitle"];
      let popUpExclude = this.isPopup ? [] : [];
      let exclude = [
        "links",
        "download",
        "socialmedia",
        ...sideBarExclude,
        ...popUpExclude
      ];

      let length = 0;
      for (var sectionKey in this.structuredData) {
        let sectionSet = this.structuredData[sectionKey];
        if (
          (this.isJustSubtitles && this.onlyShow.includes(sectionKey)) ||
          (!this.isJustSubtitles && !exclude.includes(sectionKey))
        ) {
          for (var fieldKey in sectionSet) {
            let fieldValue = sectionSet[fieldKey];

            if (
              (this.isPopup === true && fieldValue.showInPopUp) ||
              (this.isPopup === true &&
                !fieldValue.hideInPopUp &&
                sectionKey === "subtitle") ||
              (this.isPopup !== true && !fieldValue.hideInSiteInfo)
            ) {
              orderedItems[fieldValue.order] = fieldValue;
              length = length < fieldValue.order ? fieldValue.order : length;
            }
          }
        }
      }

      for (let index = 0; index <= length; index++) {
        if (
          orderedItems[index] &&
          orderedItems[index].sectionKey === "subtitle"
        ) {
          items.push(orderedItems[index]);
        }
      }

      if (
        this.media &&
        this.media.length &&
        (this.isPopup || !this.isJustSubtitles)
      ) {
        items.push({
          sectionKey: "gallerybutton",
          value: true
        });
      }

      for (let index = 0; index <= length; index++) {
        if (
          orderedItems[index] &&
          orderedItems[index].sectionKey !== "subtitle"
        ) {
          items.push(orderedItems[index]);
        }
      }

      return items;
    },

    showLinks() {
      if (!this.isPopup) {
        return {
          links: true,
          downloadAssets: true,
          socialmedia: true
        };
      } else {
        //if any of these sections have any showInPopUp the section gets shown.
        let values = {
          links: false,
          downloadAssets: false,
          socialmedia: false
        };
        let popUpChecks = { links: true, download: true, socialmedia: true };
        for (var sectionKey in popUpChecks) {
          let sectionSet = this.structuredData[sectionKey];
          for (var fieldKey in sectionSet) {
            let fieldValue = sectionSet[fieldKey];
            if (fieldValue.showInPopUp) {
              values[sectionKey] = true;
            }
          }
        }
        return values;
      }
    }
  },
  methods: {
    //do nothing
  }
};
</script>
<style>
div {
  color: "#1e2245";
  color: "#394049";
  color: "#448532";
  color: "#1e2245";
}

.M_TA_Timeline .textareaAct {
  xxfont-weight: 500;
}
</style>
