var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"siteKeyAct_wrapper  pb-10 pb-12"},[_c('div',{staticClass:"filterWrapper"},[_c('v-text-field',{attrs:{"background-color":"#a2c2cd","solo":"","label":"List Filter","prepend-inner-icon":"mdi-text-search-variant","clearable":""},model:{value:(_vm.wordFilter),callback:function ($$v) {_vm.wordFilter=$$v},expression:"wordFilter"}})],1),(false)?_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"two-line":""}},[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.loadIntro()}}},[_c('v-list-item-avatar',{attrs:{"size":"45"}},[_c('v-img',{attrs:{"src":"/assets/landingPages/acknowledgement.jpeg"}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"font-weight-bold text-subtitle-2",domProps:{"innerHTML":_vm._s('Acknowledgement of Country')}}),(false)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Welcome')}}):_vm._e()],1)],1)],1):_vm._e(),(!_vm.showMarkerLayer.length)?_c('center',[_c('br'),_c('div',{staticClass:"ml-4 heading right"},[_vm._v("... no items")])]):_vm._e(),(!_vm.showMarkerLayer.length)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.appSettings.drawButtonWidth},on:{"click":function($event){return _vm.resetFilters()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cached")]),_vm._v(" Reset filters ")],1):_vm._e(),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" sidebarSite:"+_vm._s(_vm.sidebarSite)+";; sidebarSiteAct:"+_vm._s(_vm.sidebarSiteAct)+" ")]),_c('div',{staticStyle:{"height":"0","margin-top":"-14px"}}),_c('v-list',{staticClass:"ma-0 pa-0 "},[_c('v-list-item-group',{staticClass:"ma-0 pa-0",attrs:{"active-class":"error--text"},model:{value:(_vm.sidebarSiteAct),callback:function ($$v) {_vm.sidebarSiteAct=$$v},expression:"sidebarSiteAct"}},[_vm._l((_vm.sites),function(site,index){return [(
            _vm.layers.includes(site.properties.section_slug) &&
              (!_vm.sites[index - 1] ||
                (_vm.sites[index - 1] &&
                  _vm.sites[index - 1].properties.section_slug !=
                    _vm.sites[index].properties.section_slug))
          )?_c('div',{key:'heading_' + site.properties.index},[_c('v-divider',{staticClass:"mt-3 mb-2"}),_c('div',{staticClass:"ml-4 heading",staticStyle:{"height":"65px"}},[_c('v-avatar',{class:['profile', ("slug_" + (site.properties.section_slug))],staticStyle:{"float":"left"},attrs:{"size":"60","tile":""}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
                  site.properties &&
                    site.properties.section_slug &&
                    _vm.categoryLookup[site.properties.section_slug] &&
                    _vm.categoryLookup[site.properties.section_slug]
                      .list_image_obj &&
                    _vm.categoryLookup[site.properties.section_slug]
                      .list_image_obj.thumb
                ),expression:"\n                  site.properties &&\n                    site.properties.section_slug &&\n                    categoryLookup[site.properties.section_slug] &&\n                    categoryLookup[site.properties.section_slug]\n                      .list_image_obj &&\n                    categoryLookup[site.properties.section_slug]\n                      .list_image_obj.thumb\n                "}],attrs:{"src":("" + (_vm.categoryLookup[site.properties.section_slug]
                      .list_image_obj.thumb))}})]),_c('div',{staticClass:"ml-2",staticStyle:{"height":"60px","padding-left":"62px"},style:({
                'padding-top':
                  _vm.categoryLookup[site.properties.section_slug].padding
              })},[_c('engAndTrans',{attrs:{"value":_vm.categoryLookup[site.properties.section_slug].title}})],1)],1),_c('v-divider',{staticClass:"mt-1",staticStyle:{"opacity":".6"}})],1):_vm._e(),(_vm.layers.includes(site.properties.section_slug))?_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.wordFilter ||
              _vm.wordFilter.length <= 1 ||
              (_vm.wordFilter.length > 1 &&
                ((site.properties.Site_Marker_Number_Label &&
                  site.properties.Site_Marker_Number_Label.toLowerCase().includes(
                    _vm.wordFilter.toLowerCase()
                  )) ||
                  (site.properties.Point_Name &&
                    site.properties.Point_Name.toLowerCase().includes(
                      _vm.wordFilter.toLowerCase()
                    ))))
          ),expression:"\n            !wordFilter ||\n              wordFilter.length <= 1 ||\n              (wordFilter.length > 1 &&\n                ((site.properties.Site_Marker_Number_Label &&\n                  site.properties.Site_Marker_Number_Label.toLowerCase().includes(\n                    wordFilter.toLowerCase()\n                  )) ||\n                  (site.properties.Point_Name &&\n                    site.properties.Point_Name.toLowerCase().includes(\n                      wordFilter.toLowerCase()\n                    ))))\n          "}],key:site.properties.index,class:[
            'siteKeyAct_' + site.properties.index,
            _vm.sidebarSite === site.properties.index ? 'currentlyOpen' : ''
          ],on:{"click":function($event){$event.preventDefault();return _vm.focusOnSite(site.properties.index)},"mouseenter":function($event){return _vm.hoverOnListItem(site.properties.index)},"dblclick":function($event){return _vm.loadMoreInfo(site.properties.index)}}},[_c('v-list-item-avatar',{class:['maker', ("slug_" + (site.properties.section_slug))],attrs:{"tile":""}},[(site.properties && site.properties.icon_id)?_c('img',{attrs:{"src":("" + (_vm.markerImages[site.properties.icon_id].thumb))}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.appSettings.showSiteNumbers),expression:"appSettings.showSiteNumbers"}],staticClass:"white--text avatarSiteNumber"},[_vm._v(" "+_vm._s(site.properties.Site_Marker_Number_Label)+" ")])]),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"xxv-text":"site.properties.Point_Name"}},[_c('engAndTrans',{attrs:{"value":site.properties.Point_Name}})],1)],1)],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }