var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{attrs:{"elevation":"4"}},[(false && _vm.mappedMenuItemsFilter.length)?_c('v-card-title',{class:_vm.appSettings.headingTextColor},[_vm._v("has items")]):_vm._e(),_c('v-list',{class:_vm.$vuetify.breakpoint.mobile ? 'custScroller' : 'custScroller',staticStyle:{"overflow-y":"auto"},attrs:{"dense":"","max-height":_vm.$vuetify.breakpoint.mobile ? '350px' : '400px'}},[_vm._l((_vm.mappedMenuItemsFilter),function(item,itemIndex){return [_c('v-list-item',{key:item.index,on:{"click":function($event){_vm.loadMarkerByIdFromMenu(item.index);
          _vm.popupClicked(item);}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text font-weight-normal",staticStyle:{"line-height":"1.5rem"}},[_c('div',{staticClass:"pa-0  rounded-circle d-inline-block",style:({
                height: '24px',
                width: '24px',
                background:
                  item.section_slug && _vm.categoryLookup[item.section_slug]
                    ? _vm.categoryLookup[item.section_slug].color
                    : '',
                xborder: '1px solid grey',
                'margin-top': ''
              })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!isNaN(item.Site_Marker_Number_Label)),expression:"!isNaN(item.Site_Marker_Number_Label)"}],staticStyle:{"color":"white","text-align":"center"}},[_vm._v(" "+_vm._s(parseFloat(item.Site_Marker_Number_Label))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(isNaN(item.Site_Marker_Number_Label)),expression:"isNaN(item.Site_Marker_Number_Label)"}],staticStyle:{"color":"white","text-align":"center"}},[_vm._v("    ")])]),_vm._v("    "+_vm._s(item.Point_Name)),(_vm.categoryLookup[item.section_slug])?_c('font',[_vm._v(" | "+_vm._s(_vm.categoryLookup[item.section_slug].title))]):_vm._e()],1)],1)],1),(_vm.mappedMenuItemsFilter.length - 1 != itemIndex)?_c('v-divider',{key:item.header}):_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }