const getters = {};

const mutations = {
  SET_markerList(state, newValue) {
    state.markerList = newValue;
  },
  SET_geoJson_artwork(state, newValue) {
    state.geoJson_artwork = newValue;
  },
  SET_geoJson_signage(state, newValue) {
    state.geoJson_signage = newValue;
  },
  SET_markerImages(state, newValue) {
    state.markerImages = newValue;
  },
  SET_sections(state, newValue) {
    state.sections = newValue;
  },
  SET_categoryLookup(state, newValue) {
    state.categoryLookup = newValue;
  },
  SET_stackedMarkers(state, newValue) {
    state.stackedMarkers = newValue;
  },
  SET_events(state, newValue) {
    state.eventsFormated = newValue.eventsFormated;
    state.eventsByTime = newValue.eventsByTime;
    state.eventsLookup = newValue.eventsLookup;
  },
  SET_markersScaleOpacityClass(state, newValue) {
    state.markersScaleOpacityClass = newValue;
  }
};

const actions = {
  async marker_SetmarkersScaleOpacityClass({ commit }, value) {
    commit("SET_markersScaleOpacityClass", value);
  },
  async markers_updateData({ commit }, wpData) {
    /*
    //export site to number/name
    let data = [];
    wpData.geoJson_main_marker.features.forEach(row => {
      let { properties } = row;
      let { Site_Marker_Number, Point_Name, section_slug } = properties;
      data.push({ Site_Marker_Number, Point_Name, section_slug });
    });
    
*/

    wpData.geoJson_main_marker.features.forEach((row, index) => {
      row.properties.index = index;
    });

    if (wpData?.geoJson_main_marker?.features.length) {
      commit("SET_markerList", {
        type: "geojson",
        data: wpData.geoJson_main_marker
      });
    }

    if (wpData?.geoJson_artwork?.features.length) {
      commit("SET_geoJson_artwork", {
        type: "geojson",
        data: wpData.geoJson_artwork
      });
    }

    if (wpData?.geoJson_signage?.features.length) {
      commit("SET_geoJson_signage", {
        type: "geojson",
        data: wpData.geoJson_signage
      });
    }

    commit("SET_markerImages", wpData.markerImages);
    commit("SET_sections", wpData.sections);
    commit("SET_categoryLookup", wpData.categoryLookup);
    commit("SET_stackedMarkers", wpData.stackedMarkers);
    commit("SET_events", wpData);
  }
};

const state = {
  markerList: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },
  geoJson_artwork: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },
  geoJson_signage: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },

  eventsFormated: {},
  eventsByTime: {},
  eventsLookup: {},
  markerImages: {},
  sections: {},
  categoryLookup: {},
  stackedMarkers: {},
  markersScaleOpacityClass: "markerOpaciyClass10"
};

export default {
  state,
  getters,
  actions,
  mutations
};
