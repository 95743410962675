<template>
  <center>
    <div class="mt-5">
      <div style="width:200px">
        <v-switch
          v-model="showDescriptions"
          label="Show Descriptions"
        ></v-switch>
      </div>
      <div
        fluid
        style="width:900px;max-width:100%;"
        id="ConferenceScheduleList"
      >
        <template v-for="(timeKey, timeIndex) in eventTimeKeys">
          <div :key="`timeRow_${timeKey}`">
            <v-card class="ma-0 eventTime" outlined tile color="grey">
              <div class="eventTimeTitle  pa-2">
                {{ eventTimeKeysFormated[timeIndex] }}
              </div>
            </v-card>
            <div>
              <v-container class="grey lighten-5">
                <v-row no-gutters>
                  <v-col
                    v-for="event in eventsByTime[timeKey]"
                    :key="event.postId"
                    cols="12"
                    :md="eventTimeKeysWidths[timeIndex]"
                  >
                    <v-card class="pa-2 pt-0 mx-1 eventItem" outlined tile>
                      <div class="eventTitle">
                        {{ event.title }}
                      </div>

                      <a
                        v-if="
                          event.linkedMarkerId &&
                            sitesLookup[event.linkedMarkerId] &&
                            sitesLookup[event.linkedMarkerId].Point_Name
                        "
                        @click="
                          loadMarker(sitesLookup[event.linkedMarkerId].index)
                        "
                      >
                        <div class="eventValue">
                          <font class="subHeadingItem">location: </font
                          >{{ sitesLookup[event.linkedMarkerId].Point_Name }}
                        </div>
                      </a>

                      <div v-if="event.endTimeFormated" class="eventValue">
                        <font class="subHeadingItem">end: </font
                        >{{ event.endTimeFormated }}
                      </div>

                      <div
                        v-if="showDescriptions && event.description"
                        class="eventValue eventDescription"
                      >
                        <font class="subHeadingItem">Description: </font>

                        <div
                          style="xpadding-left:16px;color:rgb(87, 87, 87);margin-bottom: 10px;padding-bottom: 10px;xborder-bottom:1px solid grey;"
                          v-show="event.description"
                          v-html="event.description"
                        ></div>
                      </div>

                      <a
                        v-if="
                          event.linkedMarkerId &&
                            sitesLookup[event.linkedMarkerId] &&
                            sitesLookup[event.linkedMarkerId].Point_Name
                        "
                        @click="
                          loadMarker(sitesLookup[event.linkedMarkerId].index)
                        "
                      >
                        <v-btn color="secondary" raised rounded x-small>
                          Load Marker</v-btn
                        >
                      </a>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </template>
      </div>
    </div>
  </center>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
const moment = require("moment");
export default {
  name: "ConferenceScheduleList",
  data() {
    return { showDescriptions: false };
  },

  methods: {
    loadMarker(index) {
      if (!isNaN(index)) {
        eventBus.$emit("nav_schedule_setOpenState", false);

        setTimeout(() => {
          eventBus.$emit("nav_map_flyToOpenPopUp", index);
        }, 700);
      }
    }
  },

  computed: {
    ...mapState({
      markerList: state => state.markers.markerList,
      eventsFormated: state => state.markers.eventsFormated,
      eventsByTime: state => state.markers.eventsByTime,
      eventsLookup: state => state.markers.eventsLookup
    }),

    eventTimeKeys() {
      let output = Object.keys(this.eventsByTime);
      output.sort();
      return output;
    },
    eventTimeKeysFormated() {
      return this.eventTimeKeys.map(startDateTime => {
        try {
          let temp = moment(startDateTime, "YYYY-MM-DD H:m:s");
          return temp.format("dddd h:mm a");
        } catch (e) {
          return startDateTime;
        }
      });
    },
    eventTimeKeysWidths() {
      return this.eventTimeKeys.map(time => {
        return this.eventsByTime[time].length > 12
          ? 1
          : 12 / this.eventsByTime[time].length;
      });
    },

    sites() {
      return this.markerList.data.features;
    },
    sitesLookup() {
      let output = this.sites.map(row => {
        return row.properties;
      });

      let sitesLookup = {};
      output.forEach(row => {
        sitesLookup[row.post_id] = row;
      });

      if (sitesLookup) {
        //do nothingk
      }
      return sitesLookup;
    }
  }
};
</script>
<style>
#ConferenceScheduleList {
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  border: 1px solid #d7d7d7;
}
.eventDescription {
  text-align: left;
  xwidth: 300px;
  xmax-width: 80%;
}

.eventTime {
  background: #e3e3e3;
}

.eventItem {
}
.eventTitle,
.eventTimeTitle {
  font-size: larger;
}
.eventValue {
  font-size: smaller;
}
.subHeadingItem {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
